.card{
    background: $white;
    box-shadow: 0 0 5rem rgba($color: $black, $alpha: .6);
    border-radius: 1rem;
    height: 34rem;
    width: 29rem;
    transition: all .3s;

    &:hover{
        transform: scale(1.03) translateY(-3rem);
        box-shadow: .5rem .5rem 6rem rgba($color: $black, $alpha: 1);
    }
}
