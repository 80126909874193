.errorpage403 {
  width: 100vw;
  margin: 0 auto;
  display: grid;
  place-content: center;
  font-size: 1.6rem;
  max-height: 100vh;

  h2 {
    text-align: center;
  }

  &__content {
    margin-top: 30%;
  }

  &__navigation {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &--item {
      margin: 0;
      padding: 0;
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
