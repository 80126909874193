@media screen and (max-width: 780px) {
  .wrapper__utils {
    width: 100% !important;

    .skillsPage {

      &__navigation{
        margin: 0 auto !important;
      }

      &__cards-slider-wrapper--card {
        h2 {
          font-size: 2.5rem !important;
        }

        ul li{
          font-size: 1.5rem !important;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .wrapper__utils {
    .skillsPage {

      &__cards-slider-wrapper--card {
        h2 {
          font-size: 2rem !important;
        }

        ul{
          margin: 0 !important;
        }
      }
    }
  }
}

@media screen and (max-width: 460px) {
  .wrapper__utils {
    .skillsPage {
     &__cards-slider{
      max-width: 85% !important;
     }
    }
  }
}
