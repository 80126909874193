@media screen and (max-width: 1470px) {
  .dashboard {
    .sidebar {
      &__topPart {
        h2 {
          font-size: 3rem;
        }
      }

      &__navigation {
        font-size: 2.6rem !important;

        &--notificationCounter {
          height: 1.5rem !important;
          width: 1.5rem !important;
          font-size: 1.2rem !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1260px) {
  .dashboard {
    &--small {
      grid-template-columns: 10% auto !important;
    }

    .sidebar {
      &__topPart {
        h2 {
          font-size: 2.4rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1140px) {
  .dashboard {
    .sidebar {
      &__topPart {
        h2 {
          font-size: 2.2rem;
        }
      }

      &__navigation {
        font-size: 2.2rem !important;

        &--notificationCounter {
          height: 1.5rem !important;
          width: 1.5rem !important;
          font-size: 1.2rem !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .dashboard {
    .sidebar {
      &__topPart {
        visibility: hidden;
        opacity: 0;
      }
    }

    .navigation {
      &__hamburgermenu {
        visibility: hidden;
        opacity: 0;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .dashboard .sidebar__navigation--entry--small i {
    margin: 0 !important;
  }
}
