.dashboard__messages {

  .btn-group .btn{
    &:not(:last-child){
      margin-right: 1rem;
    }
  }

  .messages {

    &__content{
      margin-top: 2rem;
    }

    &__akkordion {

      overflow: hidden;

      &:first-child > .messages__group {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }
      &:last-child > .messages__group {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }

      &:nth-child(even) > .messages__group {
        background-color: lighten($color: #eee, $amount: 3);
      }

      &--content {
        transition: all 0.3s;
        animation: fadeAccordionIn 0.6s;
        background-color: $AprimaryColor;
        font-size: 1.4rem;
        color: $white;
        padding: 1rem 2.5rem;

        p{
            margin: 0;
            padding: 0;
        }
      }

      &--open {
        &:last-child > .messages__group {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
        .messages__group{
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        }
      }
    }

    &__group {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      padding: 1rem;
      background-color: #eee;
      font-size: 1.6rem;
      z-index: 3;
      transition: all 0.3s;

      &--read {
        text-decoration: line-through;
        background-color: #3498db !important;
      }


      .btn-danger {
        margin-right: 1rem;
      }
      p {
        margin: 0;
      }
    }
  }
}
