.cardSlider{
    &__cardIndicator{

        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;

        &--point{
            border: 1.5px solid $black;
            height: 1.2rem;
            width: 1.2rem;
            border-radius: 50%;
            cursor: pointer;
            transition: all .3s ease-in-out;

            &:not(:last-child){
                margin-right: 1rem;
            }

            &--active{
                background-color: #111;
                transform: scale(1.1);
            }
        }
    }
}