#start {
  background-image: linear-gradient(
      to right bottom,
      rgba($color: $primaryColor, $alpha: 0.3),
      rgba($color: $secondaryColor, $alpha: 0.3)
    ),
    url("img/bg/backgroundLanding--b.jpg");
  background-size: cover;
  background-position: center;
  color: $white;

  position: relative;

  .header__nav {
    display: flex;
    justify-content: flex-end;
    padding: 3rem 3rem 0 0;
    font-size: 3rem;
    letter-spacing: 1px;
    font-weight: 300;

    &--entry {
      transition: all 0.3s;
    }

    &--entry:hover {
      padding-bottom: 0.7rem;
      border-bottom: 3px solid $white;

      a {
        background: linear-gradient(
          to right bottom,
          rgba($color: $primaryColor, $alpha: 1),
          rgba($color: $secondaryColor, $alpha: 1)
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &--entry:not(:last-child) {
      margin-right: 4rem;
    }
  }

  a {
    color: white;
    text-decoration: none;
  }

  .landing {
    &__header {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -90%);
      font-size: 15rem;
      text-align: center;

      span {
        display: block;
        font-size: 6rem;
        font-weight: 200;
      }
    }

    &__text {
      position: absolute;
      /*left: 50%;
      transform: translateX(-50%);*/
      text-align: center;
      font-size: 2rem;
      bottom: -2%;
      letter-spacing: 2.5px;
    }
  }
}

#about {
  svg {
    height: 7rem;
  }
}
