.mobileDashboard {
  &__header {
    height: 5rem;
    width: 5rem;
    position: fixed;
    top: 1rem;
    left: 1rem;
    z-index: 8;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $secondaryColor;
    border-radius: 0.2rem;
  }

  &__openIcon {
    font-size: 4rem;
    color: $AprimaryColor;
  }

  .sidebarBG {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    background-color: rgba($color: $AprimaryColor, $alpha: 0.5) !important;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    height: calc(var(--vh, 1vh) * 100);
    width: 70%;
    background-color: $AprimaryColor;
    color: $white;
    box-shadow: none;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: all 0.3s;
    overflow-x: hidden;
    overflow-y: scroll;

    transform: translateX(-100%);

    &__closeIcon {
      font-size: 3rem;
      position: absolute;
      z-index: 11;
      top: 1rem;
      right: 1rem;
    }

    &__topPart {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &--profileImage {
        margin-top: 2.5rem;
        height: 13rem;
        width: 13rem;
        border-radius: 50%;
        object-fit: cover;
        object-position: 50% 30%;
      }

      &--userName {
        margin: 1.5rem 0;
        font-weight: 400;
        letter-spacing: 2px;
        font-size: 2.6rem;

        a {
          text-decoration: none;
          color: $white;
        }
      }
    }

    &__seperator {
      border-bottom: 2px solid rgba($color: #eeee, $alpha: 0.4);
      width: 100%;
    }

    &__navigation {
      color: $white;
      width: 100%;
      font-size: 2.5rem;
      letter-spacing: 1px;
      text-align: left;

      &--notificationCounter {
        font-family: "Montserrat", sans-serif;
        position: absolute;
        right: -0.3rem;
        top: -0.2rem;
        color: $white;
        height: 1.5rem;
        width: 1.5rem;
        background-color: #e74c3c;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
      }

      &--submenu {
        padding-top: 2rem;
        transition: all 0.3s;
        display: none;
        font-size: 2.1rem;
      }

      &--entry {
        padding: 1.5rem 2rem;
        position: relative;
        transition: all 0.3s;

        a {
          color: $white;
          text-decoration: none;
          user-select: none;

          &:hover,
          &:visited,
          &:active {
            background: transparent;
          }
        }

        i {
          position: relative;

          &:hover,
          &:active,
          &:focus {
            color: $primaryColor;
          }
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 0.5rem;
          background-color: $white;
          box-shadow: 0 0 1rem rgba($color: #000000, $alpha: 0.7);
          transition: all 0.4s;
          transform: translateX(-1rem);
        }

        &--active {
          background-color: rgba($color: #7f8c8d, $alpha: 0.7);

          & > .sidebar__navigation--submenu {
            display: block;
          }

          i {
            color: $primaryColor;
          }

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 0.5rem;
            background-color: $white;
            box-shadow: 0 0 1rem rgba($color: #000000, $alpha: 0.7);
            transform: translateX(0);
          }
        }
      }
    }
  }

  &__content {
    margin-top: 6rem;
    padding: 0 1.5rem 2rem 1.5rem;
  }
}
