@media screen and (max-width: 1220px) {

}

@media screen and (max-width: 1100px) {
  .heading-secondary--portfolio {
    font-size: 3.5rem !important;
  }
}

@media screen and (max-width: 1010px) {
  .portfolio .card {
    height: 30rem !important;
    width: 25rem !important;

    p {
      font-size: 1.7rem !important;
    }
  }
}

@media screen and (max-width: 960px) {
  .heading-secondary--portfolio {
    font-size: 3rem !important;
  }
}

@media screen and (max-width: 900px) {
  .portfolio {
    .heading {
      display: block !important;
    }
  }

  .heading-secondary--portfolio {
    display: none !important;
  }

  .portfolio .card {
    height: 42rem !important;
    width: 37rem !important;

    p {
      font-size: 3rem !important;
      font-weight: 500 !important;
    }

    a, .link {
      font-size: 2.5rem !important;

      &:hover {
        text-shadow: none !important;
      }
    }
  }

  .portfolio {
    .portfolioContainer {
      grid-template-columns: 100% !important;
      grid-template-rows: 1fr 4fr 0.5fr !important;
      grid-template-areas:
        "heading"
        "cards"
        "." !important;
    }

    &__cards {
      flex-direction: column !important;
      align-content: center !important;

      .card {
        margin-bottom: 8rem !important;
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .portfolio {
    .heading {
      h2 {
        letter-spacing: 1px !important;
        font-size: 7rem !important;
      }
    }
  }
}

@media screen and (max-width: 470px) {
  .portfolio {
    .heading {
      h2 {
        font-size: 6rem !important;
      }
    }
  }
}

@media screen and (max-width: 419px) {
  .portfolio {
    .heading {
      h2 {
        font-size: 5.5rem !important;
      }
    }
  }
  .portfolio .card {
    height: 35rem !important;
    width: 30rem !important;
    padding-bottom: 2rem !important;

    p {
      font-size: 2.5rem !important;
    }
    a, .link {
      font-size: 2rem !important;
    }
  }
}

@media screen and (max-width: 380px) {
  .portfolio .portfolioContainer {
    grid-template-rows: 0.5fr 4fr .5fr !important;
  }

  .portfolio {
    .heading {
      margin-top: 4rem !important;
      h2 {
        font-size: 4.5rem !important;
      }
    }
  }
}
