@media screen and (max-width: 1200px) {
  .about__leftSide--image {
    height: 40rem !important;
    width: 40rem !important;
  }

  .about__leftSide--text {
    font-size: 6rem !important;
  }
}

@media screen and (max-width: 1100px) {
  .about__leftSide--image {
    height: 35rem !important;
    width: 35rem !important;
    padding-left: 0 !important;
  }
}

@media screen and (max-width: 970px) {
  .about {
    grid-template-columns: 100% !important;
    grid-template-rows: 4fr 0.5fr auto !important;
    grid-template-areas:
      "heading"
      "space"
      "content" !important;

    .heading {
      display: none !important;
    }

    &__skipButton {
      display: inline-block !important;
    }

    &__leftSide {
      grid-area: heading !important;
      border-radius: 0 !important;

      &--text {
        display: none !important;
      }

      .heading--mobile {
        display: block !important;
        padding-top: 6rem;
      }
    }

    &__rightSide {
      grid-area: content !important;
    }
  }
}

@media screen and (max-width: 370px) {
  .about {
    .heading--mobile h2 {
      font-size: 7rem !important;
    }
  }

  .about__leftSide--image {
    height: 30rem !important;
    width: 30rem !important;
  }
}
