.adminAbout {
  &__buttons {
    margin-top: 1rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    & > .btn:not(:last-child) {
      margin-right: 1rem;
    }

    & > .btn {
      margin-top: 1rem;
    }

    & > .btn:hover {
      background-color: $secondaryColor;
      transform: scale(1.1);
    }
  }

  &__editor {
    font-size: 1.6rem;
    color: $white;
    padding: 1rem 3rem;
    border-radius: 0.7rem;
    background-color: rgba($color: $AprimaryColor, $alpha: 0.9);
    height: 50vh;
    overflow-y: auto;
    margin-bottom: 2rem;
  }

  &__controlButtons > .btn:first-child {
    margin-right: 2rem;
  }

  &__wrapperEditor {
    position: relative;
  }
}

#aboutSuccess {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  i.fa-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 4rem;
    cursor: pointer;
  }

  i.fa-check {
    background-color: $correctGreen;
    color: white;
    transform: scale(1);
    font-size: 5.5rem;
    border: 4px solid $correctGreen;
    border-radius: 50%;
    padding: 2rem;
    animation: fillGreen 0.7s 0.45s;
  }

  h3 {
    color: $correctGreen;
    font-size: 3rem;
    font-weight: bolder;
    letter-spacing: 1px;
  }

  p {
    color: $correctGreen;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 2px;
  }
}

.adminAboutSuccess-enter {
  opacity: 0.8;
  transform: translateY(20rem);

  div{
  transform: translateY(10rem);
  }
}
.adminAboutSuccess-enter-active {
  transform: translate(0);
  opacity: 1;
  transition: all 350ms ease-out;

    div{
  transform: translateY(0);
    transition: all 250ms ease-in 300ms;
  }
}
.adminAboutSuccess-exit {
  opacity: 1;
  transform: translate(0);

    div{
  transform: translateY(0);
  }
}
.adminAboutSuccess-exit-active {
  opacity: 0;
  transform: translateY(20rem);
  transition: all 350ms ease-out 200ms;

    div{
      opacity: 0;
  transform: translateY(10rem);
  transition: all 250ms ease-in;
  }
}

@keyframes fillGreen {
  0% {
    background-color: white;
    transform: scale(0.5);
    opacity: 0.7;
  }

  80% {
    transform: scale(1.2);
  }

  100% {
    background-color: $correctGreen;
    color: white;
    opacity: 1;
    transform: scale(1);
  }
}
