/*####################################*/
/*                          Button styling                                  */
/*####################################*/

.menuButtonLanding-enter {
  opacity: 0.8;
  transform: translateX(10rem);
}
.menuButtonLanding-enter-active {
  transform: translate(0);
  opacity: 1;
  transition: all 300ms ease-out;
}
.menuButtonLanding-exit {
  opacity: 1;
  transform: translate(0);
}
.menuButtonLanding-exit-active {
  opacity: 0.8;
  transform: translateX(10rem);
  transition: all 300ms ease-out;
}

.hamburgerButton {
  cursor: pointer;
  position: fixed;
  top: 2rem;
  right: 2rem;
  height: 6rem;
  width: 6rem;
  color: $white;
  transition: all 0.3s;
  background: $AprimaryColor;
  z-index: 1000;
  border-radius: 50%;

  .barContainer {
    transition: 0.4s ease-in-out;
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  .barElement {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0);
    width: 60%;
    height: 0.3rem;
    background-color: $white;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-duration: 75ms;
    transition-property: transform;

    &::before,
    &::after {
      content: "";
      background: $white;
      position: absolute;
      width: 100%;
      height: 0.3rem;
      display: block;
    }

    &::before {
      top: -0.9rem;
      transition: top 75ms ease 0.12s, opacity 75ms ease;
    }

    &::after {
      top: 0.9rem;
      transition: rotate(0);
      transition: top 75ms ease 0.12s,
        transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &--clicked {
      transform: translate(-50%, -50%) rotate(45deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before,
      &::after {
        content: "";
        background: $white;
        position: absolute;
        width: 100%;
        height: 0.3rem;
        display: block;
      }

      &::before {
        top: 0;
        transition: top 75ms ease, opacity 75ms ease 0.12s;
        opacity: 0;
      }

      &::after {
        top: 0rem;
        transform: rotate(-90deg);
        transition: top 75ms ease,
          transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
        transform: rotate(-90deg);
      }
    }
  }
}

/*####################################*/
/*                          Menu styling                                     */
/*####################################*/

.jm_landingMenuOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  z-index: 99;
  background-image: linear-gradient(
    to bottom,
    rgba($color: $primaryColor, $alpha: 1),
    rgba($color: $secondaryColor, $alpha: 1)
  );
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in;
}

.landingMenuOverlay {
  &__nav {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--entry {
      &:not(:last-child) {
        margin-bottom: 3rem;
      }
    }

    a {
      color: $white;
      text-decoration: none;
    }
  }
}
