
$basicFontSize: 1.6rem;
$basicFont: "Montserrat";
$white: #fff;
$black:  #000;
$grayBackground: #464646;

$primaryColor: #FFD603;
$secondaryColor: #FF8709;
$tertiaryColor: #F8BD68;

$AprimaryColor: #2c3e50;

$specialFontColor: #FF7600;

$correctGreen: #2ecc71;
$incorrectRed: #e74c3c;