.background__utils {
  background-image: linear-gradient(
    to bottom,
    rgba($color: $primaryColor, $alpha: 1),
    rgba($color: $secondaryColor, $alpha: 1)
  );
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow-x: hidden;
  overflow-y: unset;
  position: relative;

  .wrapper__utils {
    margin: 0 auto;
    height: 92vh;
    width: 90%;
    background-color: transparent;
    border-radius: 1rem;
  }
}

.wrapper__utils {
  .skillsPage {
    &__loadingSpinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      div.u-spinner-animate {
        transform: scale(1.5);
      }
    }

    &__title {
      text-align: center;
      font-weight: 300;
      font-size: 5rem;
      letter-spacing: 2px;
    }

    .backButton {
      position: fixed;
      top: 1rem;
      left: 3rem;
      height: 5rem;
      width: 5rem;

      a {
        color: $white;
        text-decoration: none;
        font-size: 3rem;
        font-weight: bolder;

        span {
          display: flex;
        }

        i {
          transition: all 0.3s;
          font-size: 4rem;
        }

        &:hover > span > i {
          transform: rotate(360deg);
        }
      }
    }

    &__navigation {
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 2.6rem;
      font-weight: 200;
      cursor: pointer;
      margin: 0 8rem;
      text-align: center;
    }

    &__cards-slider-wrapper--card {
      min-height: 50rem;
      height: fit-content;
      background-color: rgba($color: $AprimaryColor, $alpha: 0.4);
      color: $white;
      transition: all 0.3s;
      transform: scale(0.9);
      border-radius: 0.6rem;
      cursor: pointer;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      padding-bottom: 2rem;

      h2 {
        font-size: 3rem;
        text-align: center;
        letter-spacing: 4px;
        margin-top: 0;
        padding-top: 3rem;
      }

      ul {
        margin-left: 2rem;
        padding-right: 2rem;
      }

      ul li {
        font-size: 2rem;
        letter-spacing: 1.5px;
        padding: 1rem 0;
      }

      &--active {
        background-color: rgba($color: $AprimaryColor, $alpha: 1);
        transform: scale(1);
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
          0 10px 10px rgba(0, 0, 0, 0.22);
      }
    }

    &__cards-slider {
      position: relative;
      max-width: 70%;
      margin: 3rem auto 0 auto;
      padding-bottom: 3rem;

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 50rem;
        position: absolute;
        top: 0;
        left: 0;
      }

      &-wrapper {
        display: flex;
        transition: all 0.3s ease-in-out;

        &--card {
          flex: 1;
          min-width: 100%;
        }
      }
    }
  }
}
