.fadeInController{

    transition: opacity .55s ease-in, transform .8s ease-in;
    opacity: 0;
    transform: translate3d(0, -60px, 0) scale(.995);

    &__in{
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1);
    }
}