.popUpWindowPortfolio-enter {
  opacity: 0.8;
  transform: scale(0.6);
  .popUpWindow__content {
    transform: translateY(10rem);
    opacity: 0.7;

    &--educationEntry {
      opacity: 0;
      transform: translateX(-8rem);
    }
  }
}
.popUpWindowPortfolio-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 300ms ease-out;

  .popUpWindow__content {
    transform: translateY(0);
    opacity: 1;
    transition: all 250ms ease-out 50ms;

    &--educationEntry {
      opacity: 1;
      transform: translateX(0);
      transition: all 250ms ease-in-out 300ms;
    }
  }
}
.popUpWindowPortfolio-exit {
  transform: scale(1);
  .popUpWindow__content {
    transform: translateY(0);
    opacity: 1;

    &--educationEntry {
      opacity: 1;
      transform: translateX(0);
    }
  }

  opacity: 1;
}
.popUpWindowPortfolio-exit-active {
  transform: scale(0.6);
  .popUpWindow__content {
    transform: translateY(7rem);
    transition: all 250ms ease-out 200ms;
    opacity: 0;

    &--educationEntry {
      opacity: 0;
      transform: translateX(-6rem);
      transition: all 250ms ease-in-out;
    }
  }

  opacity: 0;
  transition: all 300ms ease-out 200ms;
}

.popUpWindow {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($color: $AprimaryColor, $alpha: 1);
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  z-index: 1001;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  i.fa-close {
    font-size: 5rem;
    position: absolute;
    top: 4rem;
    right: 5rem;
    cursor: pointer;
    transition: color 0.25s, transform 0.15s 0.1s;

    &:hover {
      color: #2980b9;
      transform: rotate(90deg);
    }
  }

  &__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
  }

  &__title {
    align-self: flex-start;
    margin-left: 10rem;
    font-size: 3rem;
    padding-bottom: 2rem;

    &--seperator {
      width: 100%;
      height: 1rem;
      margin-top: -3.5rem;
      background-color: #2980b9;
    }
  }

  &__content {
    &--educationEntry {
      margin-top: 5rem;
    }

    &--cvContent {
      margin-top: 5rem;
      padding: 0 2rem;

      p {
        text-align: center;
      }
    }

    position: relative;
    height: 100%;
    height: calc(var(--vh, 1%) * 100);
    width: calc(52% - 3rem);
    color: $white;
    border-radius: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .popUpWindow__entry {
      width: 85%;
      margin: 0 auto 5rem auto;

      &:last-child {
        margin-bottom: 13rem;
      }

      &--h3 {
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 1rem;
        letter-spacing: 2px;
        font-size: 2.5rem;
      }

      &--line {
        display: flex;
        flex-direction: row;
        font-size: 1.6rem;
        flex-wrap: wrap;
        align-items: center;

        p {
          margin: 0;
          margin-top: 1rem;
          letter-spacing: 1px;
        }

        & > *:not(:last-child) {
          margin-right: 1rem;
        }

        & > *:not(:first-child) {
          margin-left: 1rem;
        }
      }

      &--sp {
        display: flex;
        align-items: center;
      }

      &--sepPoints {
        font-weight: bolder;
      }

      &--fp {
        font-weight: bolder;
        font-style: italic;
      }

      &--tp {
        font-weight: bolder;
        font-style: italic;
      }
    }
  }
}

.m-dash-edu {
  margin-top: 1rem;
}
