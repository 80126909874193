.btn {
  cursor: pointer;
  padding: 1rem 2rem;
  border-radius: 0.6rem;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
  transition: all 0.3s;
  border: none;

  &-danger {
    color: #fff;
    background-color: #e74c3c;

    &:hover {
      background-color: darken($color: #e74c3c, $amount: 7);
    }
  }

  &-success {
    color: #fff;
    background-color: #27ae60;

    &:hover {
      background-color: darken($color: #27ae60, $amount: 7);
    }
  }

  &-midBlue {
    color: $white;
    background-color: $AprimaryColor;

    &:hover {
      background-color: darken($color: $AprimaryColor, $amount: 7);
    }
  }

  &-round {
    cursor: pointer;
    border-radius: 50%;
    height: 6rem;
    width: 6rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    transition: all 0.3s;
    animation: scaleRoundButtonUp 0.3s;
    position: relative;
    user-select: none;

    &:hover {
      transform: translateY(-1rem);
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
    }

    span {
      margin: 0;
      padding: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 5rem;
      font-weight: 400;
    }
  }
}
