.jmPage {
  min-height: 80vh;
  width: 100%;
  overflow: hidden;

  &--landing {
    height: calc(var(--vh, 1vh) * 100);
    min-height: 450px;
  }
}

.datenschutz {
  font-size: 1.6rem;
  padding: 2rem;
  margin-top: 5rem;

  .btn-round {
    display: inline-block;
    position: fixed;
    top: 2rem;
    left: 2rem;
    font-size: 1.2rem;
    text-decoration: none;

    span {
      font-size: 4rem;
    }
  }
}

.impressum {
  font-size: 1.6rem;
  padding: 2rem;
  margin-top: 5rem;

  .btn-round {
    display: inline-block;
    position: fixed;
    top: 2rem;
    left: 2rem;
    font-size: 1.2rem;
    text-decoration: none;

    span {
      font-size: 4rem;
    }
  }
}
