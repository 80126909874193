@media screen and (max-width: 540px) {
  .dashboard__about .about__editGroup {
    flex-direction: column-reverse !important;
    align-items: flex-start !important;

    p {
      margin: 0;
      margin-top: 1rem;
    }

    a {
      width: auto !important;
    }

    .btn {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}
