@media screen and (max-height: 385px) {
    .landingMenuOverlay__nav a {
        font-size: 3rem !important;
    }

    .landingMenuOverlay__nav--entry:not(:last-child) {
        margin-bottom: 1.5rem !important;
    }
}

@media screen and (max-height: 307px) {
    .landingMenuOverlay__nav a {
        font-size: 3rem !important;
    }

    .landingMenuOverlay__nav--entry:not(:last-child) {
        margin-bottom: 0 !important;
    }
}