.caroussel {
  &__cardIndicator {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    &--point {
      border: 1.5px solid $black;
      height: 1.2rem;
      width: 1.2rem;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      &--active {
        background-color: #111;
        transform: scale(1.1);
      }
    }
  }

  &__cards-slider {
    position: relative;
    max-width: 70%;
    margin: 3rem auto 0 auto;
    padding-bottom: 3rem;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 50rem;
      position: absolute;
      top: 0;
      left: 0;
    }

    &-wrapper {
      display: flex;
      transition: all 0.3s ease-in-out;

      &--card {
        flex: 1;
        min-width: 100%;
      }
    }
  }

  &__cards-slider-wrapper--card {
    min-height: 50rem;
    height: fit-content;
    background-color: rgba($color: $AprimaryColor, $alpha: 0.4);
    color: $white;
    transition: all 0.3s;
    transform: scale(0.9);
    border-radius: 0.6rem;
    cursor: pointer;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    padding-bottom: 2rem;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      content: "";
    }

    h2 {
      font-size: 3rem;
      text-align: center;
      letter-spacing: 4px;
      margin-top: 0;
      padding-top: 3rem;
    }

    ul {
      margin-left: 2rem;
      padding-right: 2rem;
    }

    ul li {
      font-size: 2rem;
      letter-spacing: 1.5px;
      padding: 1rem 0;
    }

    &--active {
      background-color: rgba($color: $AprimaryColor, $alpha: 1);
      transform: scale(1);
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
    }
  }
}
