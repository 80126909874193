.dashboard__website {
  &--navigation {
    width: 100%;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba($color: #eeeeee, $alpha: 0.95);
    a {
      text-decoration: none;
      color: $AprimaryColor;
      font-size: 2rem;
      transition: all 0.3s;
      letter-spacing: 1px;
      font-weight: 500;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      &:hover {
        color: $secondaryColor;
      }
    }
  }
}

.dashboard__website--activeSubMenu {
  border-bottom: 3px solid $secondaryColor;
}

@media screen and (max-width: 799px) {
  .dashboard__website {
    &--navigation {
      display: flex;
      flex-direction: column;
      border-bottom: none !important;

      a {
        &:not(:last-child) {
          margin-right: 0 !important;
          margin-bottom: 1rem !important;
        }

        &:hover {
        color: $secondaryColor  !important;
      }
      }
    }
  }

  a.dashboard__website--activeSubMenu {
      color: $secondaryColor !important;
    border-bottom: none !important;
  }
}
