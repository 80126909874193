.loginPage {
  background-image: linear-gradient(
    to right bottom,
    rgba($color: $primaryColor, $alpha: 1),
    rgba($color: $secondaryColor, $alpha: 1)
  );
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;

  .loginForm {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    width: 35rem;
    height: 45rem;
    color: $black;
    border-radius: 1rem;
    box-shadow: 0 0 1rem rgba($color: #000000, $alpha: 0.9);

    &__header {
      margin: 3rem 0 6rem 0;
      letter-spacing: 2px;
      font-size: 4rem;
    }

    &__form {
      margin: 0 auto;
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .inputContainer {
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;

        label {
          align-self: flex-start;
          font-size: 1.6rem;
        }
      }

      input:not([type="submit"]) {
        background-color: $AprimaryColor;
        font-family: "Montserrat", sans-serif;
        color: $white;
        border: none;
        border-radius: 0.4rem;
        height: 3rem;
        padding: 1rem;
        font-size: 2rem;

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: white;
          font-family: inherit;
          font-size: 2rem;
        }
      }

      input[type="submit"] {
        -webkit-appearance: none;
        text-align: center;
        cursor: pointer;
        background: #27ae60;
        font-family: "Montserrat", sans-serif;
        border: none;
        font-size: 2rem;
        color: $white;
        align-self: center;
        border-radius: 0.4rem;
        height: 4rem;
        width: 8rem;
      }
    }
  }

  .errorMsg {
    background-color: red;
    color: white;
    width: 50%;
    max-width: 80%;
    margin: 0 auto;
    padding: 1rem 2rem;
    font-size: 1.6rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 300ms;
  }

  .errorDivLogin-enter {
    opacity: 0;
    transform: translateY(-100px) !important;
  }
  .errorDivLogin-enter-active {
    opacity: 1;
    transform: translate(0);
    transition: all 300ms;
  }

  .errorDivLogin-exit {
    opacity: 1;
    transform: translate(0);
  }
  .errorDivLogin-exit-active {
    opacity: 0;
    transform: translateY(-150px);
    transition: all 300ms;
  }
}
