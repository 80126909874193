.u-seperator {
  border-top: 0.3rem solid $white;
}

.u-error-message-input {
  color: red;
  font-size: 1.2rem;
}

.u-firstTextLeterBig {
  font-family: "Charm", cursive;
  font-size: 4.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

.u-notransition {
  transition: none !important;
}

.u-align-center {
  display: flex;
  align-items: center;
}

.u-modal-window {
  background-color: rgba($color: $black, $alpha: 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 1000;
  color: $black;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    background-color: $white;
    display: flex;
    flex-direction: column;
    padding: 3rem;
    font-size: 2.5rem;
    border-radius: 1rem;
  }

  &__buttons {
    margin-top: 6rem;

    & > button:first-child {
      margin-right: 1rem;
    }
  }
}

.u-spinner-animate {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.u-spinner-animate div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: animate_spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.u-spinner-animate div:nth-child(1) {
  animation-delay: -0.45s;
}
.u-spinner-animate div:nth-child(2) {
  animation-delay: -0.3s;
}
.u-spinner-animate div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes animate_spinner {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
