.contact {
  padding-top: 20rem;
  padding-bottom: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .contactWrapper {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    border-radius: 1rem;
    box-shadow: 0 1px 6rem rgba($color: #000000, $alpha: 1);

    padding: 5rem 2rem;
    background-image: linear-gradient(
        110deg,
        rgba($color: $tertiaryColor, $alpha: 0.5) 0%,
        rgba($color: $tertiaryColor, $alpha: 0.5) 60%,
        transparent 60%
      ),
      url("img/bg/programming_bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 60%;
    margin: 0 auto;
    color: white;

    form {
      width: 60%;
    }

    .formGroup {
      margin-bottom: 6rem;

      &:not(:last-child) {
        margin-bottom: 4rem;
      }

      label {
        display: inline-block;
        font-family: "Lato", sans-serif;
        font-weight: 700;
        font-size: 2rem;
        margin-top: 0.5rem;
        padding: 0 0.5rem;
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
        transition: all 0.4s;
      }

      input[type="submit"] {
        -webkit-appearance: none;
      }

      input:not([type="submit"]) {
        @include inputStyleContact($AprimaryColor);
        height: 3rem;
        width: 91%;
        display: block;

        &:placeholder-shown + label {
          transform: translateY(-4.4rem);
          opacity: 0;
          visibility: hidden;
        }

        &:not(:placeholder-shown) {
          background: rgba($color: $AprimaryColor, $alpha: 0.9);
        }

        &::placeholder {
          color: rgba($color: $white, $alpha: 0.8);
        }

        &:valid {
          border-bottom: 4px solid $correctGreen;
        }

        &:invalid {
          border-bottom: 4px solid $incorrectRed;
        }

        &:focus {
          outline: none;
          box-shadow: 1rem 2rem 7rem $black;
          transform: translateY(-1rem);
        }
      }

      textarea {
        @include inputStyleContact($AprimaryColor);
        resize: none;
        height: 15rem;
        width: 83%;

        &:not(:placeholder-shown) {
          background: rgba($color: $AprimaryColor, $alpha: 0.9);
        }

        &::placeholder {
          color: rgba($color: $white, $alpha: 0.8);
        }

        &:focus {
          outline: none;
          box-shadow: 1rem 2rem 7rem $black;
          transform: translateY(-1rem);
        }
      }
    }

    .contact__submit {
      @include inputStyleContact($incorrectRed);
      width: 30%;

      &:hover {
        box-shadow: 1rem 2rem 7rem $black;
        transform: translateY(-1rem);
      }
    }

    .formGroup:first-child > input {
      width: 99%;
    }

    #sendMessage-spinner {
      border-radius: 1rem;
      position: absolute;
      background-color: rgba($color: #000000, $alpha: 0.5);
      transition: all 0.6s;
      z-index: 10;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    #contactSuccess {
      border-radius: 1rem;
      position: absolute;
      background-image: linear-gradient(
        to right bottom,
        rgba($color: $primaryColor, $alpha: 1),
        rgba($color: $secondaryColor, $alpha: 1)
      );
      transition: all 0.6s;
      z-index: 10;
      opacity: 0;
      visibility: hidden;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      transform: translateY(-25rem);

      .contact__success--circle {
        position: absolute;
        cursor: pointer;
        font-size: 7rem;
        top: 2rem;
        right: 2rem;
        transition: all 0.3s;
        &:hover {
          color: $AprimaryColor;
        }
      }

      h3 {
        font-size: 5rem;
        letter-spacing: 2px;
        margin: 0;
      }

      p {
        font-size: 3rem;
        margin: 0;
      }
    }
  }

  .heading-secondary--contact {
    align-self: flex-end;
    margin: 0;
    margin-bottom: -5rem;
    margin-right: 2rem;
    padding: 0;
    font-size: 8rem;
  }
}
