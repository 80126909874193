@media screen and (max-width: 716px){
    .dashboard__messages .messages__group{
          flex-direction: column-reverse !important;
      align-items: flex-start !important;

      input{
          margin-bottom: 1rem;
          margin-top: 1rem !important;
      }
    }

      .dashboard__messages .btn-group{
          margin-top: 2rem !important;
          margin-bottom: 1rem !important;
      }
}

@media screen and (max-width: 314px){
   .dashboard__messages .btn-group{

    display: flex;
    flex-direction: column;

        .btn:not(:last-child){
            margin-right: 0 !important;
            margin-bottom: 1rem;
        }
   }
}

@media screen and (max-width: 260px){
    .dashboard__messages .messages__group{
          font-size: 1.4rem !important;
    }
}