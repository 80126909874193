.adminProjects {
  .btn-round {
    position: fixed;
    bottom: 1.6rem;
    right: 2.5rem;
  }

  &__entry {
    margin-top: 3rem;

    h3 {
      font-size: 2.2rem;
    }

    &--dates {
      display: flex;
      align-items: center;
      font-size: 2rem;
      margin: 2rem 0;

      .m-dash-edu {
        margin: 0;
      }

      p {
        margin: 0;
      }
    }

    &--buttons button:first-child {
      margin-right: 1rem;
    }
  }

  &__edit {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    z-index: 1000;
    background-color: rgba($color: $black, $alpha: 0.6);
    display: flex;
    justify-content: center;
    align-items: center;

    &--content {
      height: 95vh;
      width: 95%;
      overflow-x: auto;
      overflow-y: auto;
      border-radius: 1rem;
    }

    form {
      background-color: $white;
      padding: 2rem 3rem;
      border-radius: 1rem;
    }

    &--flexGroup {
      display: flex;
      align-items: center;

      & > div {
        margin-right: 2rem;
      }

      .fa {
        font-size: 2.6rem;
        color: $incorrectRed;
        cursor: pointer;
      }
    }

    .content {
      background-color: white;
      padding: 3rem;
      border-radius: 1rem;
    }

    & input[type="text"],
    & input[type="number"],
    & select {
      height: 3rem;
      width: 40rem;
      margin-bottom: 1rem;
      margin-right: 2rem;
      padding: 0.5rem 1rem;
      border: 2px solid $AprimaryColor;
      border-radius: 0.5rem;
      font-size: 1.6rem;
    }

    select {
      height: 4.5rem !important;
    }

    .buttons {
      margin-top: 2rem;

      & > input:first-child {
        margin-right: 1rem;
      }
    }
  }
}
