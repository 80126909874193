.about {
  padding-bottom: 10rem;
  margin-bottom: 20rem;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% auto;
  grid-template-rows: 1fr 3fr 2.5fr;
  grid-template-areas:
    "sidebar sidebar . heading ."
    "sidebar sidebar textContent textContent textContent"
    "sidebar sidebar textContent textContent textContent";

  &__fixedSkip {
    position: fixed;
    right: 0;
    bottom: 4rem;
    z-index: 20;
    background-color: rgba($color: $secondaryColor, $alpha: .7);
    height: 5rem;
    width: 5rem;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    animation: fadeInFixedButton .25s;

    span{
      font-size: 4rem;
      color: $white;
    }
  }

  .heading {
    grid-area: heading;
    place-self: center;
    margin-bottom: 4rem;

    h2 {
      font-size: 8rem;
      margin: 0;
      display: inline-block;
      letter-spacing: 7px;
      text-transform: uppercase;
    }

    .u-seperator {
      border-color: $secondaryColor;
      margin-top: -1rem;
    }
  }

  &__skipButton {
    color: $black;
    font-size: 6rem;
    text-decoration: none;
    display: none;
    margin-bottom: 5rem;
  }

  &__leftSide {
    border-bottom-right-radius: 1rem;
    background-image: linear-gradient(
      to bottom,
      rgba($color: $primaryColor, $alpha: 0.9),
      rgba($color: $secondaryColor, $alpha: 0.9)
    );
    grid-area: sidebar;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading--mobile {
      display: none;
    }

    &--text {
      font-size: 7rem;
      color: $specialFontColor;
      width: 80%;
    }

    &--image {
      border-radius: 50%;
      object-fit: cover;
      object-position: 50% 31%;
      height: 45rem;
      width: 45rem;
      padding-left: 1rem;
    }

    &--dlcv{
      text-decoration: none;
      color: $white;
      margin-top: 5rem;
      margin-bottom: 2rem;
      font-size: 2.5rem;
      letter-spacing: 1px;
      transition: all .25s;
      border-radius: 10px;

      &:hover{
        background-color: $secondaryColor;
        padding: 2rem;
      }
    }
  }

  &__socialIcons {
    margin-top: 4rem;
    margin-bottom: 4rem;

    svg {
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        transform: translateY(-1.5rem) scale(1.08);
      }
    }

    svg:not(:last-child) {
      padding-right: 2.5rem;
    }
  }

  &__rightSide {
    display: block;
    padding: 0 5rem 3.5rem 5rem;
    grid-area: textContent;
    font-size: 2rem;
    font-family: "Montserrat", "Lato", sans-serif;
    letter-spacing: 1.8px;
    line-height: 3.1rem;
    font-weight: 400;
    margin-bottom: 5rem;

    &--absatz {
      display: block;
      margin-bottom: 4rem;
    }
  }
}


@keyframes fadeInFixedButton{
  0%{
    transform: translateX(4rem);
  }

  80%{
    transform: translateX(.5rem);
  }

  100%{
    transform: translate(0);
  }
}