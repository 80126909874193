.adminSkill {
  .btn-round {
    position: fixed;
    bottom: 1.6rem;
    right: 2.5rem;
  }

  &__akkordion {
    margin-bottom: 2rem;

    &--heading {
      font-size: 3rem;
      letter-spacing: 2px;
      user-select: none;
      cursor: pointer;
      display: inline-block;

      &.open {
        i.fa-angle-down {
          transform: rotate(-180deg);
          color: $specialFontColor;
        }
      }

      i {
        font-size: 3rem;
        font-weight: bolder;
        transition: all 0.25s ease-in-out;
        margin-right: 3rem;
        cursor: pointer;
      }

      &.editmode {
        & > .span {
          border: 2px solid $incorrectRed;
          &:focus {
            outline: none;
          }
        }
        & ~ span.akkordion_icons_head > .fa-check {
          display: inline-block;
        }

        & ~ span.akkordion_icons_head > .fa-times {
          display: inline-block;
        }
      }

      &.open ~ .adminSkill__akkordion--bodyContent {
        display: block;
      }
    }

    i {
      font-size: 3rem;
      font-weight: bolder;
      transition: all 0.25s ease-in-out;
      cursor: pointer;
    }

    i.fa-check {
      margin-left: 3rem;
      font-weight: bold;
      color: $correctGreen;
      display: none;
    }

    i.fa-times {
      margin-left: 3rem;
      font-weight: bold;
      color: $incorrectRed;
      display: none;
    }

    i.fa-plus {
      margin-left: 3rem;
      font-weight: bold;
      color: $correctGreen;

      &:hover {
        color: darken($color: $correctGreen, $amount: 15);
      }
    }

    i.fa-trash {
      margin-left: 3rem;
      font-weight: bold;
      color: $incorrectRed;
    }

    i.fa-edit {
      margin-left: 3rem;
      font-weight: bold;
      color: $AprimaryColor;

      &:hover {
        color: $specialFontColor;
      }
    }

    &--bodyContent {
      display: none;
      margin-left: 4rem;

      &-heading {
        font-size: 2rem;
        display: inline-block;
        user-select: none;

        &.editmode {
          & > .span {
            border: 2px solid $incorrectRed;
            &:focus {
              outline: none;
            }
          }
          & ~ span.akkordion_icons_head > .fa-check {
            display: inline-block;
          }

          & ~ span.akkordion_icons_head > .fa-times {
            display: inline-block;
          }
        }

        &.open {
          i {
            transform: rotate(-180deg);
            color: $specialFontColor;
          }
        }

        i {
          font-size: 2.5rem;
          font-weight: bolder;
          transition: all 0.2s ease-out;
          margin-right: 2rem;
        }
      }

      &-heading.open ~ .adminSkill__akkordion--entryContent {
        display: block;
      }

      &-heading.open ~ .adminSkill__akkordion--entryContent {
        .adminSkill__akkordion--entryPoint {
          display: inline-block;
        }

        .adminSkill__akkordion--entryPoint.editmode {
          & > .span {
            border: 2px solid $incorrectRed;
            &:focus {
              outline: none;
            }
          }
          & ~ span.akkordion_icons_head > .fa-check {
            display: inline-block;
          }

          & ~ span.akkordion_icons_head > .fa-times {
            display: inline-block;
          }
        }
      }
    }

    &--entryContent {
      display: none;
    }
  }

  .newItemWrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba($color: $black, $alpha: 0.6);
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      background-color: $white;
      border-radius: 1rem;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      padding: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        font-size: 1.6rem;
        font-weight: 500;
        letter-spacing: 1px;
      }

      input {
        width: 100%;
        height: 4rem;
        padding: 0.6rem;
        font-size: 1.6rem;
      }

      .buttons {
        padding: 1rem;
        padding-left: 0;

        & > button:first-child {
          margin-right: 1rem;
        }
      }
    }
  }
}
