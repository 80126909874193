@media screen and (max-width: 1280px) {
  .popUpWindow__content {
    width: calc(70% - 3rem) !important;
  }
}

@media screen and (max-width: 900px) {
  .popUpWindow__content {
    width: calc(90% - 3rem) !important;
  }
}

@media screen and (max-width: 670px) {
  .popUpWindow {
    i.fa-close {
      top: 2rem !important;
      right: 2rem !important;
    }

    &__title {
      align-self: center !important;
      margin-left: 0 !important;
      margin-top: 4rem !important;
    }

    &__content {
      width: 100% !important;
    }
  }

  .projectContent {
    width: 85% !important;

    &__generalContent {
      margin-left: 2rem !important;
    }
  }
}
