.jm-cookieBanner {
  width: 100%;
  height: 8rem;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba($color: $AprimaryColor, $alpha: 0.7);
  color: white;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  a {
    color: white;
  }

  button {
    -webkit-appearance: none;
    cursor: pointer;
    border: none;

    background-color: $primaryColor;

    padding: 1rem 2rem;
    font-size: 1.6rem;
    border-radius: 0.5rem;
    margin-left: 5rem;
    transition: all 0.3s;

    &:hover {
      background-color: $secondaryColor;
    }

    &:focus {
      outline: none;
    }
  }
}
