.projectContent {
  font-size: 1.6rem;

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.8rem;
  }

  &__generalContent {
    margin-left: 4rem;
  }

  &__showcaseLink {
    text-decoration: none;
    color: $white;
    transition: all 0.3s;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    &:hover {
      color: #2980b9;
      letter-spacing: 1px;
    }
  }

  &__gallery {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    img {
      height: 15rem;
      border-radius: 1rem;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      margin-bottom: 2rem;
      margin-right: 1rem;
      margin-left: 1rem;

      &:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
          0 10px 10px rgba(0, 0, 0, 0.22);
        transform: scale(1.05);
      }
    }
  }
}
