@keyframes scaleRoundButtonUp {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}

@keyframes fadeAccordionIn{
  from{
    transform: translateY(-15%);
    opacity: 0.85;
  }
  to{
    opacity: 1;
    transform: translateY(0);
  }
}