.feedbackMessagesContainer {
  position: fixed;
  top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  z-index: 50000;
  flex-direction: column;
}

.feedbackMessage {
  width: 95%;
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  font-size: 1.6rem;
  letter-spacing: 0.15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  .placeholder {
    margin-right: auto;
    width: 0.5rem;
    height: 0.5rem;
  }

  i {
    margin-left: auto;
    font-size: 3rem;
    cursor: pointer;
  }

  &__success {
    background-color: rgba($color: $correctGreen, $alpha: 0.9);
  }

  &__error {
    background-color: rgba($color: $incorrectRed, $alpha: 0.9);
  }
}
