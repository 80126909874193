.jm-cvPage {
  display: grid;
  place-content: center;
  font-size: 2rem;

  .u-spinner-animate {
    margin: 4rem auto 0 auto;
  }

  button {
    background-color: white;
    margin-top: 4rem;
    border: none;
    padding: 2rem 1rem;
    font-size: 2rem;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 2px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: all 0.3s;

    &:hover {
      transform: translateY(-0.5rem);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }
  }

  .errorDiv {
    text-align: center;
    display: grid;

    p {
      color: red;
    }

    button {
      margin-top: 1rem;
    }
  }
}
