.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20rem;
  background: $grayBackground;
  color: $white;
  padding: 0 4rem;
  font-size: 1.6rem;
  letter-spacing: 2px;

  &__metaNav {
    &--navigation {
      display: flex;
      a {
        color: $white;
        text-decoration: none;
        letter-spacing: 1px;
        transition: all 0.3s;

        &:hover {
          color: $secondaryColor;
          //letter-spacing: 2px;
        }
      }
    }

    &--entry {
      margin-top: 0.5rem;
      transition: all 0.3s;

      &:hover:nth-child(even) {
        transform: rotate(2deg);
      }

      &:hover:nth-child(odd) {
        transform: rotate(-2deg);
      }
    }

    &--entry:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &__copy:hover {
    .footer__copy--text {
      margin-top: 3rem;
      transform: skew(15deg, 4deg);
    }
    .u-seperator.copyText {
      border-color: $primaryColor;
    }
  }

  &__copy--text {
    margin: 0.5rem 0 0 0;
    transition: all 0.3s;
  }

  svg {
    height: 6rem;
    transition: all 0.3s;

    &:not(:last-child) {
      padding-right: 2rem;
    }

    &:hover {
      cursor: pointer;
    }

    path {
      fill: $white !important;
      transition: all 0.3s;
    }
    circle {
      fill: $white !important;
      transition: all 0.3s;
    }
  }

  .appleMusicSVGIcon #appleMusicSvgMark {
    fill: $grayBackground !important;
  }

  .appleMusicSVGIcon:hover {
    height: 7rem;
    transform: rotate(20deg);

    #appleMusicSvgMark {
      fill: white !important;
    }

    path {
      fill: url(#a) !important;
    }
  }

  .twitterSVGIcon:hover {
    height: 7rem;
    transform: rotate(20deg);
    path {
      fill: #76a9ea !important;
    }
  }

  .instagramSVGIcon:hover {
    height: 7rem;
    transform: rotate(20deg);
    path {
      fill: url("#SVGID_1_") !important;
    }
    circle {
      fill: url("#SVGID_3_") !important;
    }
  }
}
