@media screen and (max-width: 1574px) {
  #projects {
    .heading {
      &--desktop {
        display: none !important;
      }

      &--mobile {
        display: inline-block !important;
        .heading-secondary--projects {
          font-size: 8rem;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  #projects {
    .projectCard__heading {
      flex-direction: column;

      & > div:first-child {
        margin-bottom: 2rem;
      }
    }

    .projectsSection__cards .projectCard {
      width: 90% !important;
    }
  }
}

@media screen and (max-width: 620px) {
  #projects {
    .heading {
      h2 {
        letter-spacing: 1px !important;
        font-size: 7rem !important;
      }
    }

    .projectsSection__cards .projectCard {
      width: 95% !important;
    }
  }
}

@media screen and (max-width: 540px) {
  #projects {
    .projectCard__learnMore {
      place-self: center;
    }

    .projectsSection__cards .projectCard {
      width: 100% !important;
    }

    .projectsSection__cards {
      padding: 0 1rem !important;
    }

    .projectCard {
      &.hover {
        .projectCard__learnMore {
          place-self: center;
        }
      }
    }
  }
}

@media screen and (max-width: 470px) {
  #projects {
    .heading {
      h2 {
        font-size: 6rem !important;
      }
    }
  }
}

@media screen and (max-width: 419px) {
  #projects {
    .heading {
      h2 {
        font-size: 5.5rem !important;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  #projects {
    .heading {
      h2 {
        font-size: 4.5rem !important;
      }
    }
  }
}
