.jmCaroussel {
  &__cards {
    margin: 3rem auto 0 auto;
    display: flex;
    width: 70vw;
    transition: none;
    transition: transform 0.3s;
  }

  &__card {
    background-color: #2c3e50;
    color: white;
    flex: 1 1;
    border-radius: 0.5rem;
    font-size: 1.6rem;
    min-width: 100%;
    transform: scale(0.9);
    opacity: 0.5;
    cursor: pointer;
    transition: none;

    &.active {
      animation: fadeIn 0.3s forwards;
    }
  }

  &__cardContent {
    padding: 0 5rem;
    font-family: "Montserrat";

    h2 {
      text-align: center;
      font-size: 3.5rem;
      letter-spacing: 2px;
      margin-bottom: 3.5rem;
    }

    ul {
      padding-bottom: 2rem;
    }

    ul li {
      margin: 2rem 0;
      font-size: 2rem;
      letter-spacing: 1px;
    }
  }

  &__indicator {
    width: fit-content;
    margin: 0 auto;
    display: flex;
    place-content: center;
    position: relative;
  }

  &__indicatorBullet {
    font-size: 2rem;
    margin-right: 2rem;
    cursor: pointer;
    height: 1rem;
    width: 4rem;
    border-radius: 2rem;
    background-color: white;

    &--activeCursor {
      position: absolute;
      display: block;
      background-color: #2c3e50;
      height: 1rem;
      width: 4rem;
      border-radius: 2rem;
      top: 0;
      left: 0;
    }
  }
}

@keyframes fadeIn {
  from {
    transform: scale(0.9);
    opacity: 0.5;
    cursor: pointer;
  }

  to {
    transform: scale(1);
    opacity: 1;
    cursor: default;
  }
}

@media screen and (max-width: 1165px) {
  .jmCaroussel__cardContent {
    h2 {
      font-size: 3rem;
    }
  }
}

@media screen and (max-width: 730px) {
  .jmCaroussel__cardContent {
    padding: 0 2.5rem;

    ul li {
      font-size: 1.6rem;
      margin: 1rem 0;
      letter-spacing: 0;
    }
  }
}

@media screen and (max-width: 680px) {
  .jmCaroussel__cardContent {
    padding: 0 1.5rem;

    h2 {
      font-size: 2.5rem;
    }

    ul li {
      font-size: 1.5rem;
    }
  }
}
