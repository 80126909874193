@media screen and (max-height: 500px) {
    .loginPage__container {
        height: 30rem !important;
    }
    .loginPage__heading {
        margin: 2rem 0 2rem 0 !important;
        font-size: 3.5rem !important;
    }

    .loginPage input:not([type="submit"]) {
        height: 2rem !important;
        font-size: 1rem !important;
        padding: 1.5rem 0.5rem 1rem .5rem !important;
    }
}

@media screen and (max-width: 450px) {
    .loginPage__container {
        width: 30rem !important;
        height: 40rem !important;
    }
}

@media screen and (max-width: 325px) {
    .loginPage__container {
        width: 25rem !important;
        height: 35rem !important;
    }

        .loginPage__heading {
        margin: 2rem 0 2rem 0 !important;
        font-size: 3.5rem !important;
    }
}