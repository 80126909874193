#projects {
  margin-top: 30rem;
  padding-bottom: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  .heading {
    &--desktop {
      display: inline-block;
    }

    &--mobile {
      display: none;
    }
  }

  .heading-secondary--projects {
    text-align: center;
    margin: 0;
    display: inline-block;
    letter-spacing: 4px;
    text-transform: uppercase;
  }

  .u-seperator {
    border-color: $secondaryColor;
  }
}

.projectsSection {
  width: 100%;

  &__errorMsg {
    font-size: 3rem;
    color: $incorrectRed;
    margin-top: 10rem;
  }

  &__cards {
    position: relative;
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 2rem;

    .bg-image {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;
      animation: addBlur 0.5s;
      filter: blur(3px);

      border-radius: 1rem;

      transition: all 0.3s;

      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    .projectCard {
      position: relative;
      overflow: hidden;
      border-radius: 0.5rem;
      width: 85%;

      &:not(:last-child) {
        margin-bottom: 6rem;
      }

      &__learnMore {
        border: 2px solid;
        background-color: rgba($color: #000000, $alpha: 0.5);
        display: inline-block;
        align-self: flex-start;
        padding: 1.5rem 2.5rem;
        font-size: 2rem;
        color: $white;
        font-family: "Montserrat";
        font-weight: 600;
        letter-spacing: 2px;
        transform: translateY(10rem);
        visibility: hidden;
        opacity: 0;
        cursor: pointer;
        transition: all 0.3s 0.6s;

        &.odd {
          border-color: $secondaryColor;

          &:hover {
            border-color: $primaryColor;
          }
        }

        &.even {
          border-color: $primaryColor;

          &:hover {
            border-color: $secondaryColor;
          }
        }

        &:focus {
          outline: none;
        }
      }

      &__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .u-seperator {
          transition: all 0.4s 0.15s;
        }

        .u-seperator.even {
          border-color: $primaryColor !important;
        }

        .u-seperator.odd {
          border-color: $secondaryColor !important;
        }

        &--h2 {
          font-size: 3rem;
          letter-spacing: 2px;
          margin-bottom: 0;
          transition: all 0.3s;
        }

        &--previewLink {
          i {
            margin-right: 0.5rem;
            font-size: 2rem;
            transition: all 0.3s;
          }

          a {
            color: $white;
            font-size: 2rem;
            text-decoration: none;
            letter-spacing: 1px;
            transition: all 0.3s ease-in 0.15s;
          }
        }
      }
    }

    .projectCard__container {
      z-index: 10;

      color: $white;
      transition: all 0.3s;
      padding: 2rem;
      min-height: 30rem;
      max-height: fit-content;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      display: flex;
      flex-direction: column;
      border-radius: 0.7rem;
      position: relative;
    }

    p {
      font-size: 1.6rem;
      margin-top: 5rem;
      transition: all 0.4s ease-in-out 0.15s;
      padding: 1rem;
      background-color: rgba($color: #000000, $alpha: 0.5);
      border-radius: 2px;
    }

    .projectCard__learnMore {
      position: absolute;
      bottom: 1.6rem;
    }
  }

  .hover {
    border-radius: 0.5rem;

    .bg-image {
      animation: noBlur 0.6s;
      transform: scale(1.1);
      border-radius: 1rem;
      filter: blur(0);
    }

    .projectCard__learnMore {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    .projectCard__heading {
      &--h2 {
        transform: translateX(-100rem);
      }

      .u-seperator {
        transform: translateX(250rem);
        opacity: 0;
        visibility: hidden;
      }

      &--previewLink {
        i {
          transform: translateY(-6rem);
          visibility: hidden;
          opacity: 0;
        }

        a {
          display: inline-block;
          transform: translateX(6rem);
          visibility: hidden;
          opacity: 0;
        }
      }
    }

    p {
      transform: translateY(15rem);
      opacity: 0;
      visibility: hidden;
    }
  }
}

@keyframes noBlur {
  0% {
    filter: blur(3px);
  }

  40% {
    filter: blur(1.5px);
  }

  60% {
    filter: blur(0.5px);
  }

  100% {
    filter: blur(0);
  }
}

@keyframes addBlur {
  0% {
    filter: blur(0);
  }

  40% {
    filter: blur(0.5px);
  }

  60% {
    filter: blur(1.5px);
  }

  100% {
    filter: blur(3px);
  }
}
