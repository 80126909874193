@mixin inputStyleContact($bgColor) {
    color: $white;
        font-family: "Lato", sans-serif;
        padding: 1rem 0.5rem;
        font-weight: 700;
        font-size: 2rem;
        border-radius: 0.4rem;
        border: none;
        background: rgba($color: $white, $alpha: 0.5);
        box-shadow: 0 0 5rem rgba($color: $black, $alpha: 0.6);
        background: rgba($color: $bgColor, $alpha: 0.6);
        transition: all 0.3s;
}