@media screen and (max-width: 1500px) {
  .contact .contactWrapper {
    width: 70% !important;
  }
}

@media screen and (max-width: 1300px) {
  .contact .heading-secondary--contact {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 1205px) {
  .contact .contactWrapper {
    width: 80% !important;
  }

  .contact .heading-secondary--contact {
    font-size: 7rem !important;
  }
}

@media screen and (max-width: 820px) {
  .contact {
    padding-bottom: 0 !important;
    .contactWrapper {
      width: 1000% !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      flex-direction: column-reverse;

      background-image: linear-gradient(
          110deg,
          rgba($color: $tertiaryColor, $alpha: 0.5),
          rgba($color: $tertiaryColor, $alpha: 0.5)
        ),
        url("img/bg/programming_bg.jpg") !important;
      padding-top: 2rem !important;

      form {
        width: 100% !important;

        input:not([type="submit"]) {
          width: 99% !important;
        }

        textarea {
          width: 99% !important;
        }
      }

      h2 {
        align-self: auto !important;
        margin: 0 !important;
        padding-bottom: 4.5rem;
      }
    }

    #contactSuccess {
      border-radius: 0 !important;
    }
  }
}
