#lightBox {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.6);
  display: grid;
  place-items: center;

  i.fa-times {
    font-size: 4rem;
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 1rem;
    background-color: rgba($color: #000000, $alpha: 0.6);
    padding: 0.6rem;
    border-radius: 1rem;
  }

  i.fa-chevron-right,
  i.fa-chevron-left {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 4rem;
    cursor: pointer;
    color: $white;
    background-color: rgba($color: #000000, $alpha: 0.6);
    padding: 0.6rem;
    border-radius: 1rem;
  }

  i.fa-chevron-right {
    right: 2rem;
  }

  i.fa-chevron-left {
    left: 2rem;
  }
}
