.portfolio {
  background-image: linear-gradient(
    to bottom,
    rgba($color: $primaryColor, $alpha: 0.9),
    rgba($color: $secondaryColor, $alpha: 0.9)
  );
  color: $white;
  transform: skew(0, -7deg);
  text-align: center;

  .heading {
    margin-top: 8rem;
    color: $black;
    grid-area: heading;
    transform: skew(0, 7deg);
    place-self: center;
    margin-bottom: 4rem;
    display: none;

    h2 {
      font-size: 8rem;
      margin: 0;
      display: inline-block;
      letter-spacing: 7px;
      text-transform: uppercase;
    }

    .u-seperator {
      border-color: $secondaryColor;
      margin-top: -1rem;
    }
  }

  .portfolioContainer {
    display: grid;
    grid-template-columns: 2% auto 2%;
    grid-template-areas:
      "heading heading heading"
      ". . ."
      ". cards ."
      ". . .";

    &.mz {
      grid-template-rows: 1fr .5fr 4fr 1fr;
    }

    &.ez {
      grid-template-rows: 2fr 1.5fr 4fr 3fr;
    }
  }

  .heading-secondary--portfolio {
    grid-area: heading;
  }

  &__cards {
    grid-area: cards;
    display: flex;
    align-items: center;
    align-content: space-between;
    justify-content: center;
    flex-wrap: wrap;
    transform: skew(0, 7deg);
    padding-bottom: 4rem;
    width: 100%;
    height: 110%;

    .fadeInScrollCard {
      padding-right: 5rem;
      padding-left: 5rem;
    }

    .card {
      color: $black;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      i {
        margin-top: 2rem;
        font-size: 11rem;
      }

      p {
        font-size: 2rem;
        font-weight: 600;
        padding: 0 3rem;
      }

      a,
      p.link {
        text-decoration: none;
        color: $secondaryColor;
        font-weight: 200 !important;
        letter-spacing: 1px;
        font-size: 2rem;
        margin-bottom: 2rem;
        transition: all 0.3s;
        cursor: pointer;

        &:hover {
          text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.8);
        }
      }

      &:not(:last-child) {
        margin-right: 15rem;
      }
    }
  }
}
