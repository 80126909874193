.jm-AccordionMenu {
  &__point {
    margin: 0;
    padding: 0;
  }

  &__pointHeader {
    cursor: pointer;
    user-select: none;
    background-color: red;
  }

  &__pointContent {
    padding: 2rem 1rem;

    &.open {
      visibility: visible;
      display: block;
    }

    &.close {
      visibility: hidden;
      display: none;
    }
  }
}
