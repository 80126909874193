.dashboard {
  display: grid;
  grid-template-columns: 18% auto;
  grid-template-rows: 6rem auto;
  grid-template-areas:
    "sidebar navigation"
    "sidebar content";
  transition: all 0.3s;
  overflow: hidden !important;
  max-height: calc(var(--vh, 1vh) * 100);

  &--small {
    grid-template-columns: 6% auto;
  }

  .sidebar {
    grid-area: sidebar;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    background-color: $AprimaryColor;
    border-top-right-radius: 6.5rem;
    border-bottom-right-radius: 1rem;
    color: $white;
    box-shadow: 0 0 1rem rgba($color: #000000, $alpha: 0.7);
    z-index: 10;
    margin-right: -0.5rem;
    overflow-y: auto;
    -ms-overflow-style: none; // IE 10+
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      display: none;
    }

    &__topPart {
      font-size: 2.5rem;
      letter-spacing: 2px;
      font-weight: 600;
      text-align: center;
      transition: all 0.3s;
      visibility: visible;
      opacity: 1;

      &--small {
        opacity: 0;
        transform: translateX(-150%);
      }

      h2 {
        margin: 4rem 0;
      }
    }

    &__seperator {
      width: 100%;
      border-bottom: 2px solid rgba($color: #eeeeee, $alpha: 0.5);
    }

    &__navigation {
      color: $white;
      font-size: 3.5rem;
      letter-spacing: 1px;
      text-align: left;

      &--notificationCounter {
        font-family: "Montserrat", sans-serif;
        position: absolute;
        right: -0.3rem;
        top: -0.2rem;
        color: $white;
        height: 2rem;
        width: 2rem;
        background-color: #e74c3c;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
      }

      &--entry {
        padding: 1.5rem 2rem;
        position: relative;
        transition: all 0.3s;

        i {
          position: relative;

          &:hover {
            color: $primaryColor;

            & + span {
              color: $primaryColor;
            }
          }
        }

        &--small {
          display: flex;
          align-items: center;

          i {
            margin-left: 1rem;
            font-size: 3.5rem;
          }
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 0.5rem;
          background-color: $white;
          box-shadow: 0 0 1rem rgba($color: #000000, $alpha: 0.7);
          transition: all 0.4s;
          transform: translateX(-1rem);
        }

        &--active {
          background-color: rgba($color: #7f8c8d, $alpha: 0.7);

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 0.5rem;
            background-color: $white;
            box-shadow: 0 0 1rem rgba($color: #000000, $alpha: 0.7);
            transform: translateX(0);
          }
        }
      }

      &--title {
        display: inline-block;
        opacity: 1;
        visibility: visible;
        transition: all 0.3s;

        &:hover {
          color: $primaryColor;
        }

        &--small {
          opacity: 0;
          visibility: hidden;
          display: none;
          transform: translateX(-100%);
        }
      }
    }

    a {
      text-decoration: none;
      color: $white;
    }
  }

  .navigation {
    z-index: 5;
    grid-area: navigation;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0.6rem 1rem -1rem rgba(0, 0, 0, 0.7);
    transition: all 0.3s;

    &__hamburgermenu {
      margin-left: 3rem;
      transition: all 0.4s;
      cursor: pointer;

      &:hover {
        & > div {
          border-bottom: 3px solid lighten($color: $AprimaryColor, $amount: 3);
          box-shadow: 1px 3px 0.7rem 0px rgba(0, 0, 0, 0.7);
        }
      }

      &--bar {
        width: 4rem;
        border-bottom: 3px solid $AprimaryColor;
        transition: all 0.3s;

        &:not(:last-child) {
          margin-bottom: 0.9rem;
        }
      }
    }

    &__profileSec {
      margin-right: 3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $AprimaryColor;
      position: relative;
      transition: all 0.3s;

      &--active {
        background-color: $AprimaryColor;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        color: $white;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.3rem;
      }

      &--icon {
        transition: all 0.3s;
        transition: color 0;
      }

      font-size: 1.6rem;

      div {
        cursor: pointer;
      }

      div:not(:last-child) {
        margin-right: 1rem;
      }

      div:first-child {
        position: relative;
      }

      &--notificationCounter {
        position: absolute;
        right: 0;
        bottom: 0;
        color: $white;
        height: 2rem;
        width: 2rem;
        background-color: #e74c3c;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
      }

      &--profilePic {
        height: 5rem;
        width: 5rem;
        border-radius: 50%;
        object-fit: cover;
        object-position: 50% 40%;
      }
    }

    &__userMenu {
      position: absolute;
      top: 100%;
      left: 0;
      background-color: $AprimaryColor;
      width: 100%;
      padding: 0 0 1rem 0;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      transform: translateX(120%);
      transition: all 0.3s;
      visibility: hidden;
      opacity: 0;
      cursor: default !important;
      z-index: 12;

      &--entry {
        margin-left: 1.2rem;
        cursor: default !important;

        &:nth-child(2) {
          position: relative;
        }
      }

      &--notificationCounter {
        position: absolute;
        left: 47%;
        top: -0.5rem;
        color: $white;
        height: 2rem;
        width: 2rem;
        background-color: #e74c3c;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
      }

      &--entry:not(:last-child) {
        margin-bottom: 1.2rem;
      }

      a {
        font-size: 2rem;
        color: $white;
        text-decoration: none;
        text-align: left;
        transition: all 0.3s;

        &:hover {
          color: $primaryColor;
        }
      }
    }
  }

  &__content {
    grid-area: content;
    padding: 3rem;
    overflow-y: scroll;
    max-height: 87.2vh;
    scroll-behavior: smooth;
    padding-bottom: 2rem;
  }
}
