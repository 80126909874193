@media screen and (max-width: 1130px) {
  #start {
    .landing {
      &__header {
        font-size: 13rem !important;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  #start {
    .landing {
      &__header {
        font-size: 11rem !important;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  #start {
    .header__nav {
      justify-content: center !important;
      padding-right: 0 !important;
    }
  }
}

@media screen and (max-width: 850px) {
  #start {
    .landing {
      &__header {
        font-size: 9rem !important;

        &--underText {
          font-size: 5rem !important;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  #start {
    .header {
      display: none !important;
    }

    .landing {
      &__header {
        font-size: 7rem !important;

        &--underText {
          font-size: 4rem !important;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  #start {
    .landing {
      &__header {
        font-size: 8rem !important;

        &--underText {
          font-size: 3rem !important;
        }
      }
    }
  }
}

@media screen and (max-width: 430px) {
  #start {
    .landing {
      &__header {
        font-size: 8rem !important;

        &--underText {
          font-size: 4rem !important;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  #start {
    .landing {
      &__header {
        font-size: 7rem !important;

        &--underText {
          font-size: 3rem !important;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  #start {
    .landing {
      &__header {
        font-size: 6rem !important;
      }
    }
  }
}
