@media screen and (max-width: 1190px) {
  .footer {
    svg {
      height: 4rem !important;
    }
  }
}

@media screen and (max-width: 1110px) {
  .footer {
    flex-direction: column;
    padding: 5rem 0 !important;
    height: 30rem !important;

    &__metaNav {
      padding-bottom: 5rem;
    }

    &__socialIcons {
      padding-bottom: 5rem;
    }

    &__copy {
      text-align: center;
    }

    &__copy:hover {
      .footer__copy--text {
        margin-top: 0 !important;
        transform: skew(0, 0) !important;
      }
      .u-seperator.copyText {
        border-color: transparent !important;
      }
    }

    .u-seperator {
      display: none;
    }
  }
}

@media screen and (max-width: 560px) {
  .footer__metaNav--navigation {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .footer__metaNav--entry:not(:last-child) {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 490px) {
  .footer__copy {
    padding: 0 2rem !important;
  }
}
